import Swiper from 'swiper';

(function ($) {

    let core = new Swiper(".swiper-soft", {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        touchStartPreventDefault: false,
        speed: 1000,
        grabCursor: true,
    });
    $(document).ready(function () {

        core.on('slideChange', function () {
            console.log(core.realIndex);
            let slide_numb = core.realIndex + 1;
            $('.section_core_features .titles .item').removeClass('active');
            $('.section_core_features .titles .item[data-item='+slide_numb+']').addClass('active');
        });

        $('.section_core_features .titles .item .title').on('click', function () {
            if($(this).parent().hasClass('active')) return false;

            let cur_slide = $(this).parent().data('item') - 1;
            $(this).closest('.titles').find('.item').removeClass('active');
            $(this).parent().addClass('active');
            core.slideTo(cur_slide, 1000);
        })
    });

})(jQuery);