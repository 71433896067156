(function ($) {

    $('.slider-logotypes').liMarquee({
        scrollStop: false,
        circular: true,
        startShow: true,
        hoverStop: false,
    });


    $(document).ready(function () {

        setTimeout(function () {
            $("#cookie-law-info-bar").addClass("show");
        }, 15000);

        $(".accordion-footer .title").on("click", function (e) {
            if(!$(this).parent().hasClass("-active")) {
                $("ul.accordion-footer li").removeClass("-active");
                $("ul.accordion-footer li .address").slideUp();
                $(this).parent().addClass("-active");
                $(this).parent().find(".address").slideDown();
            }
        });

        //lets talk popup
        $(".wrap-address-popup .country-title").on("click", function (e) {
            if (!$(this).parent().hasClass("-active")) {
                $("ul.wrap-address-popup li").removeClass("-active");
                $("ul.wrap-address-popup li .address").slideUp();
                $(this).parent().addClass("-active");
                $(this).parent().find(".address").slideDown();
            }
        });

        $(".popup-opener a, a.popup-opener").on("click", function (e) {
            e.preventDefault();
            let popup_id = $(this).attr("href");
            if($("body").hasClass("-open-menu")) {
                $("body").removeClass("-open-menu");
                setTimeout(function () {
                    $("body").addClass("-open-popup");
                    $(popup_id).addClass("-open");
                }, 300)
            } else {
                $("body").addClass("-open-popup");
                $(popup_id).addClass("-open");
            }
            if($(this).closest(".site-header").length > 0) {
                $(popup_id).data("event", "Contact_us_header_submit");
            } else if($(this).prop('href').indexOf("#lets_talk") >= 0 && $(this).closest(".main-menu").length > 0) {
                $(popup_id).data("event", "Contact_us_menu_submit");
            } else if($(this).prop('href').indexOf("#calculator") >= 0 && $(this).closest(".main-menu").length > 0) {
                $(popup_id).data("event", "Get_price_menu_submit");
            } else if($(this).closest(".section_lets_talk").length > 0) {
                $(popup_id).data("event", "Contact_us_footer_submit");
            } else if($(this).closest(".section-contact-banner").length > 0) {
                $(popup_id).data("event", "lets_talk_article_submit");
            } else {
                $(popup_id).data("event", "");
            }
        });

        $(".close-button").on("click", function (e) {
            $(this).closest(".popup, .thnks-popup").removeClass("-open");
            $("body").removeClass("-open-popup -open-thanks");
            $(".popup .wrapper").animate({
                scrollTop: 0
            }, 0);
        });

        $(".popup .wrapper").scroll(function() {
            if($(this).scrollTop() > $(".popup .wrapper .form-box .title-box:not(.-float)").height() * 2) {
                $(".popup .wrapper .form-box .title-box:not(.-float)").addClass("-hidden");
            } else {
                $(".popup .wrapper .form-box .title-box:not(.-float)").removeClass("-hidden");
            }
        });

    });

})(jQuery);