import {gsap} from "gsap";
import {initSplitText, initSplitTitle, addRedDot} from './common/help_functions.es6';
import { handleUTMSource } from './custom/utmSource.es6';

handleUTMSource();

document.addEventListener('DOMContentLoaded', () => {
    if (window.innerWidth > 993) {
        initSplitTitle('.split-title');
        gsap.effects.splitTitle('.split-title');
    } else {
        addRedDot('.split-title');
    }
    initSplitText('.split');

});

require("./vendor/intl-tel-input-master/build/js/intlTelInput-jquery.min.js");
require("./vendor/cursor-hover-effect-gsap-master.js");
require("./custom/custom-cursor.es6");
require("./vendor/modal.js");
require("./vendor/isInViewport.jquery.js");
require("./vendor/limarquee.js");
require("./custom/header.es6");
require("./custom/forms.es6");
require("./custom/footer.es6");


(function ($) {
    $(".animated-titles").length > 0 && require("./custom/animated-titles.es6");
    $(".section_info_collapse").length > 0 && require("./template-parts/section_info_collapse.es6");
    $(".section_move_logotypes").length > 0 && require("./template-parts/section_move_logotypes.es6");
    $(".section_about_us").length > 0 && require("./template-parts/section_about_us.es6");
    $(".section_video_with_popup, .section_video").length > 0 && require("./template-parts/section_video_with_popup.es6");
    $(".section_case_studies").length > 0 && require("./template-parts/section_case_studies.es6");
    $(".section_reviews").length > 0 && require("./template-parts/section_reviews.es6");
    $(".section_insights").length > 0 && require("./template-parts/section_insights.es6");
})(jQuery);
