(function ($) {

    $(document).ready(function () {
        scr_top();
        $(window).scroll(function (event) {
            scr_top();
        });

        $(".main-menu .left a").each(function (index) {
            let css_delay = (index + 1) * .05;
            $(this).css("animation-delay", css_delay + "s");
        }).hover(
            function () {
                $(".main-menu .left a").addClass("-hover-effect");
            },
            function () {
                $(".main-menu .left a").removeClass("-hover-effect");
            }
        );

        $(".main-menu .right .animate, .main-menu .right a, .lets-play").each(function (index) {
            let css_delay = (index + 1) * .05;
            $(this).css("animation-delay", css_delay + "s");
        });

        $(".open-menu-button").on("click", function (e) {
            $("body").addClass("-open-menu");
        });
        $(".close-menu-button").on("click", function (e) {
            $("body").removeClass("-open-menu");
            $(".main-menu .wrapper").animate({
                scrollTop: 0
            }, 0);
        });
        $(".scroll-up").on("click", function (e) {
            e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 2000);
        });

        $("a[href*='#']:not(.popup-opener)").on('click', function (e) {
            e.preventDefault();
            let elementClick = $(this).attr("href");
            let headerHeight = $(".site-header").innerHeight();
            if ($(window).width() > 1279) headerHeight = 0;

            $('html,body').animate({
                scrollTop: $(elementClick).offset().top - headerHeight
            }, 2000);
        });

        //back to top button and donate button
        let btn = $('.scroll-top-btn');
        let donateButton = $('.donate-btn');

        $(window).scroll(function () {
            if ($(window).scrollTop() > 20) {
                donateButton.addClass('collapse');
            } else {
                donateButton.removeClass('collapse');
            }

            if ($(window).scrollTop() > 80) {
                btn.addClass('show');
            } else {
                btn.removeClass('show');
            }
        });

        btn.on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({scrollTop: 0}, 500);
        });
    });

    function scr_top() {
        let scrTop = window.pageYOffset;
        if (scrTop > 20) {
            $("body").addClass("-fixed-header");
        } else {
            $("body").removeClass("-fixed-header");
        }
    }

})(jQuery);