export default class Timer {
    constructor(el) {
        this.stopwatch = el;
        this.currentLapTime = 0;
    }

    startTimer() {
        this.time = new Date();
        this.currentLapTime = 0;
    }

    updateTimer() {
        let currentTimeDate = new Date(this.millisecondsFromStart());
        this.stopwatch.innerText = this.leadingZero(currentTimeDate.getMinutes()) + ":" + this.leadingZero(currentTimeDate.getSeconds()) + ":" + this.leadingZero(Math.round(currentTimeDate.getMilliseconds() / 10));
        this.currentLapTime = Math.round(this.millisecondsFromStart() / 10) * 10;
    }

    leadingZero(n) {
        if (n < 10) {
            return "0" + n;
        }
        return n;
    }

    millisecondsFromStart() {
        var now = new Date();
        return (now - this.time);
    }
}
