(function ($) {
    $(document).ready(function () {

        //************************************
        // Search input
        //************************************

        $('#search').click(function () {
            $('.search-form-wrap').addClass('open-search');
        });

        $('.close-input-button').click(function () {
            $('.search-form-wrap').removeClass('open-search');
        });
    });

})(jQuery);