import Swiper from 'swiper';


(function ($) {

    let team = new Swiper(".swiper-team", {
        loop: false,
        spaceBetween: 0,
        speed: 1000,
        touchStartPreventDefault: false,
        grabCursor: true,
        slidesPerView: 'auto'
    });

})(jQuery);