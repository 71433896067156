import Swiper from 'swiper';
import gsap from 'gsap';

(function ($) {

    let services = new Swiper(".swiper-services", {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1000,
        grabCursor: true,
        touchStartPreventDefault: false,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 2
            },
            1400: {
                slidesPerView: 3
            }
        }
    });

})(jQuery);