import {gsap} from "gsap";

(function ($) {
    let loading = false;

    $('.section_info_collapse').each(function () {
        if ($(this).hasClass('videos')) {
            let firstItem = $(this).find(".item:first-child");
            setTimeout(function () {
                toggleCollapse(firstItem.find(".item-title"), true, true);
            }, 800);
        }
    });

    $('.section_info_collapse .item .item-title').on('click', function () {
        if (!loading) {

            loading = true;
            if(!$(this).hasClass('active')) {
                toggleCollapse($(this).closest('.section_info_collapse').find('.item-title.active'));

                toggleCollapse($(this));
            } else {
                toggleCollapse($(this), false);
            }

            setTimeout(function () {
                loading = false;
            }, 300);
        }
    });


    $('body').delegate('.video-mob .button', 'click', function () {
        if ($(this).hasClass('play')) {
            $(this).closest('.video-mob').find('video').trigger('play');
            $(this).removeClass('play');
        } else {
            $(this).closest('.video-mob').find('video').trigger('pause');
            $(this).addClass('play');
        }
    });

    function toggleCollapse(item_title, current_item = true, first_init = false) {
        let content = item_title.next();
        let lines = content.find('.fade-el');
        let isOpen = item_title.hasClass('active');
        let videoUrl = item_title.closest('.item').data('video');
        let imageUrl = item_title.closest('.item').data('image');
        let videoBox = item_title.closest('.section_info_collapse').find(".video-box");
        let headerHeight = $(".site-header").innerHeight();

        const tl = gsap.timeline({paused: true})
            .fromTo(lines, {
                    y: "-10px",
                    opacity: 0
                },
                {
                    y: 0,
                    opacity: 1,
                    stagger: 0.1,
                    duration: 0.3,
                    ease: "none"
                })
            .fromTo(content, {
                    height: 0,
                    y: "-20px"
                },
                {
                    height: "auto",
                    y: 0,
                    ease: 'none',
                    duration: 0.5
                }, "<");

        item_title.toggleClass('active');

        if (isOpen) {
            gsap.fromTo(tl, {
                progress: 1,
            }, {progress: 0, duration: 0.3});
        } else {
            gsap.to(tl, {
                progress: 1,
                duration: 0.5
            });
            if($(window).width() < 768) {
                if (!first_init && item_title.hasClass("active")) {
                    setTimeout(function () {
                        $('html,body').animate({
                            scrollTop: item_title.offset().top - headerHeight
                        }, 400);
                    }, 500);
                }
            }
        }

        if (videoBox.length > 0 && current_item && $(window).width() > 767 && item_title.hasClass("active") && (videoUrl || imageUrl)) {
            if(videoUrl) {
                item_title.closest('.section_info_collapse').find('.video-mob video').trigger('pause').next().addClass('play');
                videoBox.append('<video class="new-item" autoplay muted loop><source src="' + videoUrl + '">Your browser does not support the video tag.</video>');
            } else if(imageUrl) {
                item_title.closest('.section_info_collapse').find('.video-mob video').trigger('pause');
                videoBox.append('<img class="new-item" src="' + imageUrl + '">');
            }

            setTimeout(function () {
                videoBox.find('.new-item').addClass('show');
                videoBox.find('.new-item').prev().removeClass('new-item show')
            }, 300);
            setTimeout(function () {
                videoBox.find('video:not(.new-item), img:not(.new-item)').remove();
            }, 600);
        } else if (videoBox.length > 0 && current_item && $(window).width() < 768) {
            item_title.closest('.section_info_collapse').find('.video-mob video').trigger('pause').next().addClass('play');
            videoBox.find('video, img').remove();
        }

        if(!current_item) {
            videoBox.find('video, img').remove();
        }
    }
})(jQuery);