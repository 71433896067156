$(document).ready(function () {

    $('.hover-reveal-inner').mousemove(function( event ) {
        event.stopImmediatePropagation();
    });
    $('.hover-reveal')
        .mousemove(function () {
            $(this).find('.hover-reveal-inner').addClass('-show');
        })
        .mouseout(function () {
            $(this).find('.hover-reveal-inner').removeClass('-show');
        });

});