import simpleParallax from 'simple-parallax-js';

(function ($) {

    $(document).ready(function () {
        let image = document.querySelectorAll('.img-thumbnail');
        let instance = new simpleParallax(image, {
            delay: .6,
            transition: 'cubic-bezier(0,0,0,1)',
            customWrapper: '.parallax-container',
        });

    });

})(jQuery);