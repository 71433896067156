import Swiper, { Autoplay } from 'swiper';
import gsap from 'gsap';

(function ($) {

    let slider = new Swiper(".about-us-slider", {
        modules: [Autoplay],
        autoplay: {
            delay: 3000,
            disableOnInteraction: true,
            pauseOnMouseEnter: false
        },
        slidesPerView: 'auto',
        speed: 1000,
        touchStartPreventDefault: false,
        loop: true,
        on: {
            touchMove: function (swiper, e) {
                gsap.to(swiper.slides, {
                    scale: 1.03,
                    duration: 0.3
                });
            },

            touchEnd: function (swiper, e) {
                gsap.to(swiper.slides, {
                    scale: 1,
                    duration: 0.3
                });
            },
        },
    });
})(jQuery);