(function ($) {
    $(document).ready(function () {

        //************************************
        // Search result page
        //************************************

        $(".section_browse_categories .units-categories .show_more_categories").on("click", function () {
            //detect show more categories button
            if ($(this).hasClass("show-more")) {
                $(this).removeClass("show-more").addClass("show-less").html("<span>View less</span>");
                $(this).hide().fadeIn(800);
                $(this).closest(".units-categories").removeClass("-hidden");
            } else if ($(this).hasClass("show-less")) {
                $(this).removeClass("show-less").addClass("show-more").html("<span>View more</span>");
                $(this).hide().fadeIn(800);
                $(this).closest(".units-categories").addClass("-hidden");
            }
        });

        //button show more results
        $('.search-result-wrap .insights .item:nth-child(-n+16)').addClass('visible');

        if ($('.search-result-wrap .insights .item').length > 16) {
            $(".search-result-wrap").addClass("hide-other-posts");
        } else {
            $(".search-result-wrap").removeClass("hide-other-posts");
        }

        $(".search-result-wrap .load-more-btn").on("click", function () {
            $(".search-result-wrap").removeClass("hide-other-posts");
            $(".search-result-wrap .insights .item").addClass("visible");
        });
    });

})(jQuery);