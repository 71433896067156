import {gsap} from "gsap";
import {isThereСlass, initSplitText, initSplitTitle, addRedDot} from './common/help_functions.es6';
import {initExpertiseSection} from "./template-parts/section_expertise.es6";
import { handleUTMSource } from './custom/utmSource.es6';

handleUTMSource();

document.addEventListener('DOMContentLoaded', () => {
    if (window.innerWidth > 993) {
        initSplitTitle('.split-title');
        gsap.effects.splitTitle('.split-title');
    } else {
        addRedDot('.split-title');
    }
    initSplitText('.split');
    if (isThereСlass('.expertise')) initExpertiseSection();

});

require("./vendor/intl-tel-input-master/build/js/intlTelInput-jquery.min.js");
require("./vendor/cursor-hover-effect-gsap-master.js");
require("./custom/custom-cursor.es6");
require("./vendor/modal.js");
require("./vendor/isInViewport.jquery.js");
require("./vendor/bootstrap-transition.js");
require("./vendor/limarquee.js");
require("./custom/preloader.es6");
require("./custom/header.es6");
require("./custom/forms.es6");
require("./custom/footer.es6");
require("./custom/single-insights.es6");
require("./custom/animation-popup.js");
require("./custom/sharing-popup.js");


(function ($) {

    $(".section_services").length > 0 && require("./template-parts/solutions/section_services.es6");
    $(".section_insights_slider").length > 0 && require("./template-parts/solutions/section_insights.es6");
    $(".section_core_features").length > 0 && require("./template-parts/solutions/section_core_features.es6");
    $(".section_what_you_do").length > 0 && require("./template-parts/solutions/section_what_you_do.es6");
    $(".animated-titles").length > 0 && require("./custom/animated-titles.es6");
    $(".section_team_quotes").length > 0 && require("./template-parts/section_team_quotes.es6");
    $(".position-ajax").length > 0 && require("./custom/position-ajax.es6");
    $(".section_info_collapse").length > 0 && require("./template-parts/section_info_collapse.es6");
    $(".section_move_logotypes").length > 0 && require("./template-parts/section_move_logotypes.es6");
    $(".section_about_us").length > 0 && require("./template-parts/section_about_us.es6");
    $(".section_video_with_popup, .section_video").length > 0 && require("./template-parts/section_video_with_popup.es6");
    $(".section_results_slider").length > 0 && require("./template-parts/projects/section_results_slider.es6");
    $(".section_results_slider_type_2").length > 0 && require("./template-parts/section_results_slider_type_2.es6");
    $(".section_team_slider").length > 0 && require("./template-parts/section_team_slider.es6");
    $(".section_other_cases").length > 0 && require("./template-parts/projects/section_other_cases.es6");
    $(".take-a-rest-js").length > 0 && require("./custom/take-a-rest-page/game");
    $(".archive-projects").length > 0 && require("./template-parts/section_case_studies.es6");
    $(".single-project-category").length > 0 && require("./custom/single-project-category.es6");
    $(".section_reviews").length > 0 && require("./template-parts/section_reviews.es6");
    $(".section_insights").length > 0 && require("./template-parts/section_insights.es6");
    $(".section_solutions").length > 0 && require("./template-parts/section_solutions.es6");
    $(".section_insights_archive").length > 0 && require("./template-parts/section_insights_archive.es6");
    $(".section_info_block").length > 0 && require("./template-parts/projects/section_info_block.es6");

    //search
    $(".search-form-wrap").length > 0 && require("./custom/search-input.es6");
    $(".search-result-wrap").length > 0 && require("./custom/search-result-page.es6");

})(jQuery);
