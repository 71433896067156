import Swiper from 'swiper';

(function ($) {

    let results_slider2 = new Swiper(".swiper-results2", {
        loop: false,
        spaceBetween: 0,
        speed: 1000,
        touchStartPreventDefault: false,
        grabCursor: true,
        slidesPerView: 'auto'
    });

})(jQuery);