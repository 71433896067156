$(document).ready(function () {

    $('.challenge').on("click", function () {
        $(".sharing-popup-wrap").fadeIn().css("display", "flex");
    });


//copy text in input
    $('.js-link-copy').on("click", function () {
        var copyText = document.getElementById("text");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    });
});
