import Swiper, { Autoplay } from 'swiper';
import gsap from 'gsap';

(function ($) {

    let slider = new Swiper(".swiper-reviews", {
        modules: [Autoplay],
        autoplay: {
            delay: 3000,
            disableOnInteraction: true,
            pauseOnMouseEnter: false
        },
        slidesPerView: 'auto',
        speed: 1000,
        touchStartPreventDefault: false,
        loop: true,
    });
})(jQuery);