import Swiper, { Autoplay, FreeMode, Mousewheel  } from 'swiper';

(function ($) {

    let cases = new Swiper(".swiper-case-studies", {
        modules: [Autoplay, FreeMode, Mousewheel],
        autoplay: {
            delay: 3000,
            disableOnInteraction: true,
            pauseOnMouseEnter: false
        },
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1000,
        touchStartPreventDefault: false,
        grabCursor: true,
        mousewheelControl: true,
        loopAdditionalSlides: 20,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 2
            },
            1400: {
                slidesPerView: 3
            }
        }
    });

})(jQuery);