(function ($) {

    const offset = 100;

    $('.section_team_quotes .quotes .item').isInViewport(function (status) {
        if (status === 'entered' && !$(this).hasClass('-animated')) {
            $(this).addClass('-animated');
        }
    }, offset)

})(jQuery);