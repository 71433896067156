import Swiper from 'swiper';

(function () {
    'use strict';

    let swiperWhat;
    const enableSwiper = function () {
        swiperWhat = new Swiper(".swiper-who", {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 1000,
            touchStartPreventDefault: false,
            grabCursor: true,
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                }
            }
        });
    };

    const breakpointChecker = function () {
        if ($(window).width() <= 992) {
            if(swiperWhat !== undefined ) return false;
            return enableSwiper();
        } else {
            if(swiperWhat === undefined) return false;
            swiperWhat.destroy(true, true);
            swiperWhat = undefined;
        }
    };

    $(document).ready(function () {
        breakpointChecker();
        $(window).on('resize', function () {
            breakpointChecker();
        });
    });

})();