$(document).ready(function () {
    if ($(".take-a-rest-js").length > 0) {
        var arr = [3, 2, 1, "go!"];
        var i = 0;
        var elem = document.getElementById('counter');

        var countDown = setInterval (function () {
            elem.innerHTML = arr[i++];

            if (i === arr.length) {
                clearInterval(countDown);
                $('.game-popup-wrap').fadeOut('slow', function () {
                    $(window).trigger('triare.game.go');
                });
            }

        }, 1000);

    }

    $('.lose-play-button').on("click", function () {
        $(".all-game-popup").fadeOut();
        $("#timer").text('00:00:00');

    });
    $('.play-button-win-1').on("click", function () {
        $(".all-game-popup").fadeOut();
        $("#timer").text('00:00:00');
        $(".result-wrap").fadeIn();
        $(".play-button-win-1").fadeOut();
        $(".forName").html("");
        $("#top-1").val("");
        $(".partner").fadeOut();
    });
    $('.play-button-win').on("click", function () {
        $(".all-game-popup").fadeOut();
        $("#timer").text('00:00:00');
        $(".result-wrap").fadeIn();
        $(".play-button-win").fadeOut();
        $(".forName").html("");
        $("#top-10").val("");
        $(".partner").fadeOut();
    });

    $('.play-button').on("click", function () {
        $(".all-game-popup").fadeOut();
        $("#timer").text('00:00:00');
        $(".result-wrap").fadeIn();
        $(".play-button").fadeOut();
        $(".partner").fadeOut();
        $(".forName").html("");
        $("#name-slow").val("");
    });
    $('.close-game-popup').on("click", function () {
        $(".all-game-popup").fadeOut();
        $("#timer").text('00:00:00');
        $(".result-wrap").fadeIn();
        $(".play-button").fadeOut();
        $(".play-button-win").fadeOut();
        $(".play-button-win-1").fadeOut();
        $(".partner").fadeOut();
        $(".input-name-js").val("");
        $(".forName").html("");
    });

    $("#counter").addClass("pulse")
});


