import simpleParallax from 'simple-parallax-js';

(function ($) {

    $(document).ready(function () {
        let page = 1;
        let range = 16;
        let loading = false;

        let image = document.querySelectorAll('.insight-thumbnail');
        let instance = new simpleParallax(image, {
            delay: .6,
            transition: 'cubic-bezier(0,0,0,1)',
            customWrapper: '.parallax-container',
        });

        $(".section_insights_archive .load-more-btn").on("click", function () {

            if (!loading) {
                loading = true;
                let parent = $(this).closest(".section_insights_archive").find(".insights");
                let section = $(this).closest(".section_insights_archive");
                let projectCatPage = [];
                let dataCategory = $(this).closest(".section_insights_archive").data("cat-page");
                let target = section.find(".load-more-box");
                let searchVal = '';
                if (dataCategory) {
                    projectCatPage.push(dataCategory);
                }

                if (projectCatPage.length > 0) {
                    target.addClass("-loading");

                    $.ajax({
                        url: ajaxMeta.ajaxurl,
                        type: "post",
                        data: {
                            action: "more_insights_ajax",
                            offset: (page * range),
                            range: range,
                            projectCat: projectCatPage,
                            s: '',
                        },
                        success: function (data) {
                            if (data.result) {
                                page++;

                                loading = false;
                                parent.append(data.result);
                                image = document.querySelectorAll('.insight-thumbnail');
                                instance = new simpleParallax(image, {
                                    delay: .6,
                                    transition: 'cubic-bezier(0,0,0,1)',
                                    customWrapper: '.parallax-container',
                                });
                                if (data.count <= range) {
                                    section.find(".load-more-box").addClass("-hidden");
                                } else {
                                    section.find(".load-more-box").removeClass("-hidden");
                                }
                                target.removeClass("-loading");
                            } else {
                                loading = false;
                                target.removeClass("-loading");
                            }
                        }
                    });
                } else {
                    target.addClass("-loading");

                    $.ajax({
                        url: ajaxMeta.ajaxurl,
                        type: "post",
                        data: {
                            action: "more_insights_ajax",
                            offset: (page * range),
                            range: range,
                            projectCat: ["all"],
                            s: searchVal,
                        },
                        success: function (data) {
                            if (data.result) {
                                page++;

                                loading = false;
                                parent.append(data.result);
                                image = document.querySelectorAll('.insight-thumbnail');
                                instance = new simpleParallax(image, {
                                    delay: .6,
                                    transition: 'cubic-bezier(0,0,0,1)',
                                    customWrapper: '.parallax-container',
                                });
                                if (data.count <= range) {
                                    section.find(".load-more-box").addClass("-hidden");
                                } else {
                                    section.find(".load-more-box").removeClass("-hidden");
                                }
                                target.removeClass("-loading");
                            } else {
                                loading = false;
                                target.removeClass("-loading");
                            }
                        }
                    });
                }
            }
        });

        $(".section_browse_categories .units-categories .show_more_categories").on("click", function () {
            //detect show more categories button
            if ($(this).hasClass("show-more")) {
                $(this).removeClass("show-more").addClass("show-less").html("<span>View less</span>");
                $(this).hide().fadeIn(800);
                $(this).closest(".units-categories").removeClass("-hidden");
            } else if ($(this).hasClass("show-less")) {
                $(this).removeClass("show-less").addClass("show-more").html("<span>View more</span>");
                $(this).hide().fadeIn(800);
                $(this).closest(".units-categories").addClass("-hidden");
            }
        });
        $(".section_browse_categories .show-categories").on("click", function () {
            //detect show more categories button
            if ($(this).hasClass("show-more")) {
                $(this).removeClass("show-more").addClass("show-less");
                $(this).closest(".section_insights_archive").find(".units-categories.-desk").removeClass("-hidden-mob");
            } else if ($(this).hasClass("show-less")) {
                $(this).removeClass("show-less").addClass("show-more");
                $(this).closest(".section_insights_archive").find(".units-categories.-desk").addClass("-hidden-mob");
            }
        });

    });

})(jQuery);